@charset "UTF-8";
/*================================================
このCSSファイルはSassから生成されています。
編集しないようにご注意ください。
================================================*/
//ブレイクポイントのmixin
@mixin media($breakpoint) {
    @media #{map-get($breakpoints, $breakpoint)} {
        @content;
    }
}

html {
  font-size: 62.5%;
  /* 10px */
  height: 100%;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  display: block;
}

/*スライドドア*/
.img_box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
  img {
    transform: translateX(-100%);
  }
}

.img_box.slide--on img {
  width: 100%;
  animation-name: door_2;
animation-duration: 0.2s;
animation-timing-function: ease-out;
animation-delay: 0.8s;
animation-iteration-count: 1;
animation-direction: normal;
animation-fill-mode: both;
animation-play-state: running;
-webkit-animation-fill-mode: both;
-moz-animation-fill-mode: both;
animation-fill-mode: both;
}

.img_box .slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #c9bc9c;
  /*-webkit-transition: all 0.3s;
  transition: all 0.3s;*/
}

.img_box.slide--on .slide-bg {
  width: 100%;
    -webkit-animation-fill-mode: both;
    -moz-animation-fill-mode: both;
    animation-fill-mode: both;
    animation-name: door;
    animation-duration: 1.4s;
      animation-timing-function: ease-out;
      animation-delay: 0.25s;
      animation-iteration-count: 1;
      animation-direction: normal;
      animation-play-state: running;
}

@keyframes door {
  0% {
    transform: translateX(-100%);
  }

  50% {
    transform: translateX(0);
  }

  100% {
    transform: translateX(100%);
  }
}

@keyframes door_2 {
  0% {
    transform: translateX(-100%);
  }

  100% {
    transform: translateX(0);
  }

}

//トップスライド
.main_image {
  margin-top: 57px;
  @include media(m) {
    margin-top: 70px;
  }
  @include media(xl) {
    margin-top: 108px;
  }
}

.image-crossfader {
    overflow: hidden;
    position: relative;
    height: 72vh;
    @include media(m) {
      height: 493px;
    }
    @include media(xl) {
      height: 76vh;
      min-height: 508px;
      max-height: 825px;
    }
}

.image-crossfader-inner:nth-child(1) {
  background-image: url(../../top/img/slide01_sp.jpg);
  @include media(ml) {
    background-image: url(../../top/img/slide01_pc.jpg);
  }
}
.image-crossfader-inner:nth-child(2) {
  background-image: url(../../top/img/slide02_sp.jpg);
  @include media(ml) {
    background-image: url(../../top/img/slide02_pc.jpg);
  }
}
.image-crossfader-inner:nth-child(3) {
  background-image: url(../../top/img/slide03_sp.jpg);
  @include media(ml) {
    background-image: url(../../top/img/slide03_pc.jpg);
  }
}
.image-crossfader-inner:nth-child(4) {
  background-image: url(../../top/img/slide04_sp.jpg);
  @include media(ml) {
    background-image: url(../../top/img/slide04_pc.jpg);
  }
}
.image-crossfader-inner:nth-child(5) {
  background-image: url(../../top/img/slide05_sp.jpg);
  @include media(ml) {
    background-image: url(../../top/img/slide05_pc.jpg);
  }
}

.image-crossfader-inner {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 3.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}
.image-crossfader-inner.is-visible {
  z-index: 1;
  opacity: 1;
}

/*メインイメージバナー*/
.main_image {
  position: relative;
}

.top_banner_wrap {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  z-index: 3;
  @include media(m) {
    top: 2rem;
  }
  @include media(xl) {
    width: 90%;
    max-width: 1600px;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
  }
}

.top_banner_img {
  width: 160px;
  margin-left: auto;
  margin-right: 1rem;
  @include media(xl) {
    margin-right: 0;
    width: 264px;
  }
}


//ムービー
.visual {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
  @include media(m) {
    width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
  @include media(xl) {
    width: 568px;
    margin-left: auto;
    margin-right: auto;
  }
}
.movie {
  video {
    width: 100%;
  }
}

.fuul_movie {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
  @include media(xl) {
    margin-top: 16px;
  }
  a {
    color: #fff;
    background-color: #c9bc9c;
    padding: 0 10px;
    line-height: 2;
    display: inline-block;
    font-size: 11px;
    @include media(xl) {
      font-size: 13px;
    }
  }
}

//ガイドライン
.guide_line_wrap {
	text-align: center;
	margin-top: 28px;
  margin-bottom: 58px;
}

.guide_line_wrap a {
	color: #333;
	font-family: sans-serif;
	font-size: 3.2vw;
	line-height: 1.5;
	border-bottom: solid 1px #333;
	display: inline-block;
	padding-bottom: 6px;
  @include media(m) {
    font-size: 12px;
  }
  @include media(xl) {
    font-size: 15px;
    margin-top: 36px;
  }
}

/*会場説明*/
.main_copy_wrap {
  background-color: #f6f6f6;
  padding-bottom: 4.8rem;
}

.top_copy {
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: -56px;
  z-index: 3;
  position: relative;
  @include media(m) {
    width: 45%;
    max-width: 398px;
  }
  @include media(xl)  {
    max-width: 587px;
    padding: 11px;
  }
}

.top_copy_inner {
  @include font_base(1.5rem);
  letter-spacing: .1em;
  line-height: 1.8;
  text-align: center;
  border: double 3px #333;
  padding: 1.3rem 0;
  @include media(xl) {
    font-size: 19px;
    padding: 21px;
  }
  h2 {
    font-weight: normal;
  }
}

.store_name {
  @include font_base(1.2rem);
  padding-top: 0.6rem;
  font-weight: bold;
  @include media(xl) {
    font-size: 14px;
    padding-top: 14px;
  }
}

.store-wedding {
  color: $main_color;
  font-family: 'Italianno', cursive;
  text-align: center;
  font-size: 3.9rem;
  font-weight: 400;
  letter-spacing: .05em;
  padding-top: 3.2rem;
  @include media(m) {
    font-size: 4.2rem;
  }
  @include media(xl) {
    font-size: 58px;
    padding-top: 54px;
  }
}

.stoe_copy {
  @include font_base(1.6rem);
  font-weight: normal;
  text-align: center;
  padding-top: 2.4rem;
  @include media(m) {
    font-size: 1.8rem;
    padding-top: 2.2rem;
  }
  @include media(xl) {
    font-size: 21px;
    padding-top: 18px;
  }
}

.copy_lead {
  @include font_base;
  margin: 2rem 5% 0;
  line-height: 1.6;
  @include media(m) {
    line-height: 1.5;
    font-size: 14px;
  }
  @include media(ml) {
    text-align: center;
  }
  @include media(xl) {
    font-size: 17px;
    line-height: 1.6;
    margin-top: 31px;
  }
}

/*選ばれる理由*/
.ttl_reason {
  @include font_base;
  text-align: center;
  line-height: 1.8;
  font-size: 1.8rem;
  font-size: 4.375vw;
  font-weight: normal;
  margin-top: 5.2rem;
  position: relative;
  @include media(m) {
    font-size: 17px;
  }
  @include media(xl) {
    font-size: 22px;
    margin-top: 101px;
  }
  &::before {
    display: inline-block;
    position: absolute;
    content: '';
    background: url(../../top/img/reason_bg.png) center no-repeat;
    width: 234px;
    height: 45px;
    top: -2.6rem;
    left: 50%;
    transform: translateX(-50%);
    background-size: contain;
    z-index: -1;
    @include media(xl) {
      width: 346px;
      height: 66px;
      top: -38px;
    }
  }
  span {
     font-family: 'Dancing Script', cursive;
     font-size: 2.2rem;
  }
}

.reason_list {
  @include media(ml) {
    width: 32.155172%;
  }
}

.reason_box {
  margin: 0 5%;
  padding-top: 2.6rem;
  @include media(s) {
    li:not(:first-child) {
      padding-top: 3.6rem;
    }
  }
  @include media(m) {
    width: 90%;
  }
  @include media(ml) {
    display: flex;
    justify-content:space-between;
    margin: 0 auto;
  }
  @include media(xl) {
    width: 90%;
    max-width: 1030px;
  }
}

.reason_image {
  width: 47.262536%;
  @include media(ml) {
    width: 73.28418%;
    margin: 0 auto;
  }
}

.reason_lead {
  width: 77%;
  box-shadow: 5px 5px #f6f6f6;
  margin-left: auto;
  margin-top: -5rem;
  z-index: 3;
  position: relative;
  background-color: rgba(255,255,255,0.96);
  @include media(m) {
    margin: -3.8rem auto 0;
    width: 96%;
  }
  @include media(ml) {
    display: flex;
    justify-content: center;
  }
  @include media(xl) {
    width: 100%;
  }
  h4 {
    font-weight: normal;
    @include font_base;
    font-size: 3.73vw;
    position: relative;
    @include media(m) {
      font-size: 1.2rem;
    }
    @include media(xl) {
      font-size: 17px;
    }
  }
}

.reason_left {
  margin-left: auto;
}

.reason_right {
  margin-right: auto;
  margin-left: 0;
}

.reason_01 {
  position: relative;
  padding: 1.6em 1rem 1rem 2rem;
  @include media(xl) {
    padding: 2em 0 1.3rem 2rem;
  }
  &::before {
    position: absolute;
    content: '01';
    font-family: 'Dancing Script', cursive;
    top: 0;
    left: 3%;
    font-size: 2.8rem;
    color: #d0d0d0;
    z-index: -1;
    @include media(xl) {
      font-size: 4.2rem;
      top: -2px;
    }
  }
}

.reason_02 {
  position: relative;
  padding: 1.6em 1rem 1rem 2rem;
  @include media(xl) {
    padding: 2em 0 1.3rem 2rem;
  }
  &::before {
    position: absolute;
    content: '02';
    font-family: 'Dancing Script', cursive;
    top: 0;
    left: 3%;
    font-size: 2.8rem;
    color: #d0d0d0;
    z-index: -1;
    @include media(xl) {
      font-size: 4.2rem;
      top: -2px;
    }
  }
}

.reason_03 {
  position: relative;
  padding: 1.6em 1rem 1rem 2rem;
  @include media(xl) {
    padding: 2em 0 1.3rem 2rem;
  }
  &::before {
    position: absolute;
    content: '03';
    font-family: 'Dancing Script', cursive;
    top: 0;
    left: 3%;
    font-size: 2.8rem;
    color: #d0d0d0;
    z-index: -1;
    @include media(xl) {
      font-size: 4.2rem;
      top: -2px;
    }
  }
}

.reason_txt {
  @include font_base;
  font-size: 1.3rem;
  padding-top: 2rem;
  @include media(s) {
    margin: 0 5%;
  }
  @include media(m) {
    font-size: 1rem;
  }
  @include media(xl) {
    font-size: 14px;
    margin: 0 28px;
  }
}

/*トピック*/
#topics {
  padding-top: 4.6rem;
}

.ttl_outer{
  text-align: center;
  z-index: 3;
  position: relative;
}

.ttl_topic {
  color: #333;
  font-size: 2.5rem;
}

.ttl_topic {
  display: inline-block;
  font-family: 'Cormorant Garamond', serif;
  font-weight: 500;
  font-size: 2.4rem;
  letter-spacing: .05em;
  text-align: center;
  position: relative;
  color:#333;
  line-height: 2;
  &::before {
    position: absolute;
    content: '';
    border-top: solid 1px #333;
    width: 80%;
    height: 1px;
    left: 50%;
    transform: translateX(-50%);
  }
  &::after {
    position: absolute;
    content: '';
    border-top: solid 1px #333;
    width: 80%;
    height: 1px;
    left: 50%;
    bottom: 0;
    transform: translateX(-50%);
  }
}

.topics_box {
  background-color: #f6f6f6;
  padding: 4rem 4%;
  margin-top: -2.5rem;
  @include media(m) {
    width: 58%;
    padding: 5rem 5% 4rem;
  }
  @include media(ml) {
    margin: -2.5rem auto 0;
  }
  @include media(xl) {
    width: 698px;
    box-sizing: border-box;
  }
}

.topics_inner {
  background-color: #fff;
  padding: 2rem 1rem;
  @include media(xl) {
    padding: 29px 40px;
  }
}

.type_box {
  display: block;
  color: #333;
  &:not(:last-child) {
    border-bottom: solid 1px #333;
    padding-bottom: 1.2rem;
  }
  &:not(:first-of-type) {
    padding-top: 1.2rem;
  }
}

.topics_type {
  background-color: #000;
  color: #fff;
  line-height: 1.5;
  letter-spacing: .05em;
  padding: 0 0.8rem;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 1rem;
  @include media(xl) {
    font-size: 12px;
    margin-bottom: 12px;
  }
}

.type_inner {
  display: flex;
  align-items: center;
}

.topic_img_outer {
  width: 24.856596%;
}

.topic_img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 90%;
  img {
    object-fit: cover;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.topic_info {
  flex: 1;
  padding-left: 1.2rem;
  @include media(xl) {
    padding-left: 39px;
  }
}

.topics_day {
  @include font_base(1.3rem);
  line-height: 1;
  padding-bottom: 0.6rem;
  @include media(xl) {
    font-size: 15px;
    padding-bottom: 17px;
  }
}

.topics_txt {
  line-height: 1.5;
  @include media(xl) {
    font-size: 14px;
  }
}

/*オススメフェア＆プラン*/
#recommended {
  margin-top: 7.2rem;
  @include media(m) {
    margin-top: 9.2rem;
  }
}

.ttl_sp {
  @include font_base;
  text-align: center;
  font-size: 1.8rem;
  font-weight: normal;
  position: relative;
  letter-spacing: .1em;
  @include media(m) {
    font-size: 2rem;

  }
  @include media(xl) {
    font-size: 23px;
  }
  span {
    font-family: sans-serif;
    font-size: 1.2rem;
    line-height: 1.5;
    display: inline-block;
    @include media(m) {
      font-size: 1.2rem;
    }
    @include media(xl) {
      font-size: 14px;
    }
  }
}

.ttl_recommended {
  @include media(xl) {
    margin-top: 14.2rem;
  }
  &::before {
    display: inline-block;
    position: absolute;
    content: '';
    background: url(../../top/img/recommended.png) center no-repeat;
    width: 318px;
    height: 45px;
    top: -3.2rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-size: contain;
    z-index: -1;
    @include media(m) {
      width: 418px;
      height: 49px;
      top: -4rem;
    }
    @include media(xl) {
      width: 560px;
      height: 66px;
      top: -5.2rem;
    }
  }
}

.ttl_privilege {
  @include media(xl) {
    margin-top: 193px;
  }
  &::before {
    display: inline-block;
    position: absolute;
    content: '';
    background: url(../../top/img/privilege.png) center no-repeat;
    width: 222px;
    height: 82px;
    top: -3.4rem;
    left: 50%;
    -webkit-transform: translateX(-50%);
    transform: translateX(-50%);
    background-size: contain;
    z-index: -1;
    @include media(m) {
      width: 232px;
      height: 86px;
    }
    @include media(xl) {
      width: 327px;
      height: 120px;
      top: -5.2rem;
    }
  }
}

/*オススメフェア*/
.ttl_category {
  font-family: 'Cormorant Garamond', serif;
  font-weight: normal;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #333;
  padding-bottom: 0.5rem;
  span {
    font-size: 1.1rem;
    display: inline-block;
    padding-left: 1.6rem;
    font-family: sans-serif;
  }
}

.recommended_box {
  @include media (m) {
    margin-top: 3.6rem;
  }
  @include media(ml) {
    display: flex;
    align-items: flex-end;
    &._reco-fair {
      flex-direction: row-reverse;
    }
  }
  @include media(xl) {
    margin-top: 53px;
  }
}

.recommended_img_wrap {
  width: 100%;
  @include media(ml) {
    width: 59.902912%;
    max-width: 617px;
  }
}

.recommended_img {
  position: relative;
  width: 100%;
  padding-top: 62.72285%;
  @include media(s) {
    margin: 2.8rem auto 0;
  }
}

.inner_img {
  object-fit: cover;
  	position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.ttl_fair {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #333;
  padding-bottom: 0.8rem;
  h5 {
    font-weight: normal;
    font-size: 1.8rem;
    @include media(xl) {
      font-size: 23px;
    }
  }
}

.recommended_wrap {
  padding-top: 3.8rem;
  margin: 0 5%;
  @include media(xl) {
    width: 1030px;
    margin: 0 auto;
  }
  .ttl_category {
    &:not(:first-of-type) {
      padding-top: 8.2rem;
      @include media(xl) {
        padding-top: 148px;
      }
    }
  }
}
.schedule {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
  color: $prime_color;
  @include media(xl) {
    font-size: 20px;
    font-weight: normal;
  }
}

.recommended_txt_wrap {
  background-color: rgba(255,255,255,0.86);
  width: 85%;
  z-index: 3;
  position: relative;
  padding: 1rem 1rem 2.4rem 1rem;
  @include media(ml) {
    box-shadow: 8px 8px #f6f6f6;
  }
  @include media(s) {
    margin: -2.2rem auto 0;
    border-bottom: solid 4px #f6f6f6;
  }
  &._reco-fair-txt {
  }
  @include media(m) {
    width: 51.21359%;
    padding: 1rem 1.8rem 2.4rem 1.8rem;
    &._reco-fair-txt {
      margin-right: -10rem;
      margin-bottom: -4rem;
      box-shadow: -8px 8px #f6f6f6;
    }
    &._reco-plan-txt {
      margin-left: -10rem;
      margin-bottom: -4rem;
    }
  }
  @include media(xl) {
    width: 56.21359%;
    &._reco-fair-txt {
      margin-right: -16.019417%;
      margin-bottom: -79px;
      padding: 31px 38px 34px;
      box-shadow: -8px 8px #f6f6f6;
    }
    &._reco-plan-txt {
      margin-left: -16.019417%;
      margin-bottom: -79px;
      padding: 31px 38px 34px;
    }
  }
}

.recommended_txt {
  dt {
    color: $prime_color;
    font-size: 1.6rem;
    line-height: 1.4;
    font-weight: bold;
    padding-top: 1.6rem;
    @include media(ml) {
      line-height: 1.5;
    }
    .number,.tax {
      font-size: 1.2rem;
    }
    @include media(m) {
      .number,.tax {
        font-size: 1.1rem;
      }
    }
    @include media(xl) {
      font-size: 19px;
      font-weight: normal;
      padding-top: 18px;
      .number,.tax {
        font-size: 14px;
      }
    }
  }
  dd {
    @include font_base;
    font-size: 1.3rem;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 4;
    overflow: hidden;
    width: 100%;
    padding-top: 2rem;
    @include media(ml) {
      -webkit-line-clamp: 3;
    }
    @include media(m) {
      font-size: 1.2rem;
    }
    @include media(xl) {
      font-size: 14px;
      line-height: 1.8;
    }
  }
  ._reco-plan {
    color: $option_color;
    span {
      display: inline-block;
    }
  }
}

.reco_btn {
  position: relative;
  display: block;
  color: #333;
  font-size: 1.4rem;
  border: solid 1px #333;
  width: 23rem;
  margin: 0 auto;
  padding: 0.8rem 5rem;
  box-sizing: border-box;
  margin-top: 3.2rem;
  @include media(m) {
    width: 18rem;
  }
  @include media(xl) {
    font-size: 18px;
    &:hover::after {
      right: 4.2rem;
    }
  }
  &::after {
    position: absolute;
    content: '';
    background: url(../../common/img/btn_allow.svg) no-repeat center;
    background-size: contain;
    top: 50%;
    transform: translateY(-50%);
    right: 6rem;
    width: 35px;
    height: 100%;
    transition: right .2s ease-in;
    @include media(m) {
      right: 3rem;
    }
  }
}

/*選べるスタイル*/
#CEREMONY {
  position: relative;
  margin-top: 6rem;
  @include media(m) {
    margin-top: 11rem;
  }
  @include media(xl) {
    margin-top: 180px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 14.4rem;
    left: 0;
    z-index: -3;
    width: 100%;
    background-color: #f6f6f6;
    height: calc(100% + -9rem);
    @include media(m) {
      height: calc(100% + -11rem);
    }
    @include media(xl) {
      top: 192px;
      height: calc(100% + -13rem);
    }
  }
}

.col_3_box {
  padding-top: 3.6rem;
  @include media(ml) {
    display: flex;
    justify-content: space-between;
  }
  li {
    width: 74%;
    @include media(ml) {
      width: 31.5517241%;
    }
    background-color: #fff;
    @include media(s) {
      &:not(:first-child) {
        margin-top: 3.2rem;
      }
      &:nth-child(2) {
        margin-left: auto;
      }
    }
    img {
      -webkit-transform: scale(1);
      -ms-transform: scale(1);
      transform: scale(1);
      -webkit-transition: all 1.2s;
      transition: all 1.2s;
      @include media(xl) {
      }
    }
  }
  a {
    display: block;
  }
}

.col_3_box {
  li {
    &:hover {
      img {
        @include media(xl) {
          -webkit-transform: scale(1.1);
          -ms-transform: scale(1.1);
          transform: scale(1.1);
          -webkit-transition: all 1.1s;
          transition: all 1.1s;
        }
      }
    }
  }
}


.expansion {
  overflow: hidden;
}

.ceremony_txt {
  display: inline-block;
  color: #333;
  font-size: 1.3rem;
  background-color: #fff;
  padding: 1.5rem 3rem 1.5rem 1rem;
  position: relative;
  @include media(xl) {
    font-size: 17px;
    padding: 20px 28px 20px 20px;
  }
  &::after {
    display: block;
    content: '>';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    right: 0;
  }
}

/*パーティー*/
#party {
  padding-top: 10rem;
  @include media(xl) {
    padding-top: 170px;
  }
}

.slide_box {
  &._party {
    margin-top: 3.2rem;
    box-shadow: 8px 8px $color_common;
    @include media(ml) {
      display: flex;
      justify-content: space-between;
    }
    @include media(xl) {
      margin-top: 52px;
    }
  }
}

.slide_box_txt {
  padding: 2rem 1.8rem;
  @include media(m) {
    padding: 0 2rem;
  }
  @include media(ml) {
    width: 40.344827%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
    @include media(xl) {
      padding: 0 5% 0 5%;
    }
  }
  dt {
    @include font_base;
    font-size: 1.8rem;
    line-height: 1;
    span {
      font-size: 1.2rem;
    }
  }
  dd {
    @include font_base;
    padding-top: 1.6rem;
    @include media(m) {
      padding-top: 2.6rem;
    }
    @include media(xl) {
      font-size: 16px;
      padding-top: 13%;
    }
  }
}

.img_wrap {
  @include media(ml) {
    width: 59.655172%;
    box-sizing: border-box;
  }
}

.reco_btn {
  &._slide-btn {
    @include media(s) {
      width: 19rem;
    }
    &::after {
      @include media(s) {
        right: 3rem;
      }
    }
    @include media(xl) {
      margin-top: 16%;
      padding: 1.2rem 5rem;
    }
  }
}

/*フォント見出し-TB*/
@media (min-width: 768px) {
  .slide_box_txt {
    dt {
      font-size: calc((20 - 16) * ((100vw - 768px) / (1023 - 768)) + 16px);
    }
  }
}

@media (min-width: 1023px) {
  .slide_box_txt {
    dt {
      font-size: 20px;
    }
  }
}

/*フォントspan-TB*/
@media (min-width: 768px) {
  .slide_box_txt {
    dt {
      span {
        font-size: calc((12 - 10) * ((100vw - 768px) / (1023 - 768)) + 12px);
      }
    }
  }
}

@media (min-width: 1023px) {
  .slide_box_txt {
    dt {
      span {
        font-size: 12px;
      }
    }
  }
}

/*フォントテキスト-TB*/
@media (min-width: 768px) {
  .slide_box_txt {
    dd {
      font-size: calc((14 - 11) * ((100vw - 768px) / (1023 - 768)) + 14px);
    }
  }
}

@media (min-width: 1023px) {
  .slide_box_txt {
    dd {
      font-size: 14px;
    }
  }
}

/*フォント見出し-PC*/
@media (min-width: 1023px) {
  .slide_box_txt {
    dt {
      font-size: calc((24 - 21) * ((100vw - 1024px) / (1600 - 1024)) + 24px);
    }
  }
}

@media (min-width: 1600px) {
  .slide_box_txt {
    dt {
      font-size: 28px;
    }
  }
}

/*フォントspan-PC*/
@media (min-width: 1023px) {
  .slide_box_txt {
    dt {
      span {
        font-size: calc((14 - 12) * ((100vw - 1024px) / (1600 - 1024)) + 14px);
      }
    }
  }
}

@media (min-width: 1600px) {
  .slide_box_txt {
    dt {
      span {
        font-size: 14px;
      }
    }
  }
}

/*フォントテキスト-PC*/
@media (min-width: 1023px) {
  .slide_box_txt {
    dd {
      font-size: calc((15 - 12) * ((100vw - 1024px) / (1600 - 1024)) + 15px);
    }
  }
}

@media (min-width: 1600px) {
  .slide_box_txt {
    dd {
      font-size: 17  px;
    }
  }
}

/* Safari 7.1+ に適用される */
_::-webkit-full-page-media, _:future, :root, body {
  -webkit-animation: safariFix 1s forwards infinite;
}
@-webkit-keyframes safariFix {
  100% {
    z-index: 1;
  }
}

/*バーチャルツアー*/
#EXPERIENCE {
  position: relative;
  margin-top: 6rem;
  @include media(xl) {
    padding-top: 93px;
  }
  &::after {
    content: '';
    position: absolute;
    top: 16.4rem;
    left: 0;
    z-index: -3;
    width: 100%;
    background-color: #f6f6f6;
    height: calc(100% + -11rem);
    @include media(m) {
      height: calc(100% + -11rem);
    }
    @include media(xl) {
      top: 322px;
      height: calc(100% + -26rem);
    }
  }
}

.ttl_cont_sub  {
  &.ttl_vr {
    @include media(s) {
      font-size: 3.4375vw;
    }
  }
}

/*料理*/
#CUISINE {
  padding-top: 11rem;
  @include media(xl) {
    padding-top: 156px;
  }
}

.slide_box {
  &._party {
    &._cuisine  {
      @include media(xl) {
        flex-direction: row-reverse;
        box-shadow: -8px 8px $color_common;
      }
    }
  }
}

/*ドレス*/
#DRESS {
  padding-top: 6rem;
  position: relative;
  &::after {
    position: absolute;
    content: '';
    top: 19.4rem;
    left: 0;
    z-index: -3;
    width: 100%;
    background-color: #f6f6f6;
    height: calc(100% + -15rem);
    @include media(m) {
      height: calc(100% + -16rem);
    }
    @include media(xl) {
      top: 294px;
      height: calc(100% + -24rem);
    }
  }
  @include media(xl) {
    padding-top: 97px;
  }
}

.cont_wrap {
  &.dress_warap {
    @include media(xl) {
      max-width: 982px;
    }
  }
}
.costume_box {
  position: relative;
  padding-top: 3.2rem;
  @include media(ml) {
    display: flex;
    justify-content: space-between;
  }
   li {
     box-sizing: border-box;
     @include media(ml) {
       flex: 0 48.26883%;
     }
     @include media(s) {
       &:not(:first-child) {
         margin-top: 4.8rem;
       }
     }
   }
  .media-link {
    display: block;
    position: relative;
    overflow: hidden;
      img {
        -webkit-transform: scale(1);
        -ms-transform: scale(1);
        transform: scale(1);
        -webkit-transition: all 1.2s;
        transition: all 1.2s;
      }
    &::before {
      position: absolute;
      content: '';
      border: double 3px #fff;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 83%;
      height: 86%;
      z-index: 3;
      box-sizing: border-box;
    }
    &::after {
      content: '';
      position: absolute;
      bottom: 0;
      left: 0;
      width: 100%;
      height: 100%;
      background-color: rgba(0, 0, 0, 0.3);
      box-sizing: border-box;
      color: #FFFFFF;
      overflow: hidden;
      -webkit-transition: all 1.2s;
      transition: all 1.2s;
    }
  }
}

@include media(xl) {
  .media-link:hover {
    img {
      -webkit-transform: scale(1.1);
      -ms-transform: scale(1.1);
      transform: scale(1.1);
      -webkit-transition: all 1.1s;
      transition: all 1.1s;
    }
  }
  .costume_box .media-link:hover::after {
    opacity: 0.4;
    -webkit-transition: all 1.2s;
    transition: all 1.2s;
  }
}

.title {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 99;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 54%;
  text-align: center;
  letter-spacing: 0.2em;
  padding: 2.6% 0;
  font-weight: 100;
  font-size: 1.5rem;
}

.ttl_under {
  font-size: 2.4rem;
  letter-spacing: .25em;
  line-height: 0.8;
  span {
    font-size: 1.3rem;
    letter-spacing: .1em;
    font-family: sans-serif;
  }
}

//バナー
#BANNER_BOX {
  margin-top: 12.2rem;
}

.sp_banner_wrap {
  padding-top: 2rem;
  @include media(ml) {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  @include media(xl) {
    padding-top: 48px;
  }
  li {
    @include media(s) {
      &:not(:first-child) {
        padding-top: 1.8rem;
      }
    }
    @include media(ml) {
      width: 49%;
    }
    &:not(:first-of-type) {
      padding-top: 1rem;
      @include media(m) {
        padding-top: 1.2rem;
      }
      @include media(xl) {
        padding-top: 24px;
      }
    }
  }
}

//バナースライダー
#banner_wrap {
  margin: 0 10%;
}

#slider {
  margin:0 auto 30px;
  @include media(s) {
    margin:0 8%;
  }
  @include media(ml) {
    max-width: 680px;
    width: 64%;
    margin: 0 auto;
  }
  @include media(xl) {
    margin:0 auto 40px;
  }
}
.slide-item img {
  width: 100%;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.slide-arrow {
  position: absolute;
  top: 50%;
}
.prev-arrow {
  left: -6%;
  width: 26px;
  height: 26px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: translateY(-50%)  rotate(225deg);
  z-index: 1;
  @include media(s) {
    width: 15px;
    height: 15px;
  }
}
.next-arrow {
  right: -6%;
  width: 26px;
  height: 26px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: translateY(-50%)  rotate(45deg);
  @include media(s) {
    width: 15px;
    height: 15px;
  }
}

.slide_wrap {
  padding-top: 1.4rem;
  @include media(m) {
    padding-top: 4.4rem;
    width: 100%;
    margin: 0 auto;
  }
  @include media(xl) {
    padding-top: 44px;
  }
}

//スライドバナー
.banner_txt {
  @include font_base;
  padding-top: 0.4rem;
}

.banner_txt-header {
  color: $color_common;
  font-family: sans-serif;
  font-size: 1.4rem;
  letter-spacing: .05em;
  line-height: 1.5;
  padding-top: 0.6rem;
  @include media(xl) {
    font-size: 16px;
  }
  a {
    color: $color_common;
  }
}

.banner_lead {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: .05em;
  padding-top: 0.4rem;
  @include media(xl) {
    font-size: 15px;
  }
}

//アクセス
#ACCESS {
  padding-top: 4.2rem;
  @include media(m) {
    padding-top: 3.2rem;
  }
  @include media(xl) {
    padding-top: 84px;
  }
}
.ttl_cont_sub._ttl-access {
  font-size: 3.4375vw;
  @include media(m) {
    font-size: 13px;
  }
  @include media(xl) {
    font-size: 19px;
  }
}

.map_img {
  width: 53%;
  margin-top: -2rem;
  z-index: 3;
  position: relative;
  @include media(ml) {
    flex: 0 0 40.60344%;
    margin-right: -9.137931%;
    margin-top: 19%;
  }
}

//グーグルマップ
.location {
  width: 92%;
  margin-left: auto;
  @include media(ml) {
    position: relative;
    width: 100%;
    padding-top: 38.9685%;
    height: 0;
  }
  iframe {
    width: 100%;
    height: 22rem;
    @include media(ml) {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
    }
  }
}

.location_wrap {
  display: flex;
  @include media(s) {
    padding-top: 3.2rem;
    flex-direction: column-reverse;
  }
  @include media(m) {
    padding-top: 3.2rem;
  }
  @include media(xl) {
    padding-top: 52px;
  }
}

.access_info {
  @include media(s) {
    padding-top: 2.1rem;
  }
  @include media(ml) {
    width: 54.6551724%;
    margin-left: auto;
    margin-top: -7%;
  }
}

.address {
  dt {
    @include font_base;
    font-size: 1.6rem;
    padding-bottom: 1.3rem;
    @include media(xl) {
      font-size: 18px;
    }
  }
  dd {
    @include font_base;
    font-size: 1.3rem;
    line-height: 1.6;
    @include media(xl) {
      font-size: 14px;
      line-height: 1.8;
    }
  }
}

.route_tel-wrap {
  @include media(ml) {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
  @include media(m) {
    padding-top: 1.2rem;
  }
  @include media(xl) {
    padding-top: 21px;
  }
}

.access_tel-outer {
  @include media(s) {
    text-align: center;
    padding-top: 3.6rem;
  }
}

.access_tel {
  font-size: 2rem;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  letter-spacing: .1em;
  padding: 1rem 3rem 1rem 6rem;
  position: relative;
  @include media(s) {
    border: solid 1px #333;
  }
  @include media(m) {
    padding: 1rem 3rem 1rem 2rem;
  }
  @include media(xl) {
    font-size: 23px;
    padding: 1rem 3rem 1rem 3rem;
  }
  &::before {
    content: '';
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: 1.8rem;
    background: url(../../common/img/icon_tel.svg) center no-repeat;
    background-size: contain;
    width: 13px;
    height: 100%;
    @include media(ml) {
      left: 0;
    }
    @include media(xl) {
      width: 17px;
    }
  }
}

.reception_time-outer {
  display: flex;
  justify-content: center;
  @include media(ml) {
    justify-content: flex-start;
  }
}

.reception_time {
  @include font_base;
  padding-top: 1.2rem;
  font-size: 1.3rem;
  text-align: right;
  @include media(xl) {
    font-size: 16px;
  }
}

//経路案内
.route_btn {
  a {
    display: block;
    color: #333;
    font-size: 1.3rem;
    position: relative;
    padding-left: 2rem;
    @include media(s) {
      margin-top: 2rem;
    }
    @include media(xl) {
      font-size: 14px;
      padding-left: 3.2rem;
    }
    &::before {
      position: absolute;
      content: '';
      top: 50%;
      transform: translateY(-50%);
      left: 0;
      background: url(../../common/img/icon_filled_point.svg) center no-repeat;
      background-size: contain;
      width: 15px;
      height: 20px;
      @include media(xl) {
        width: 18px;
        height: 29px;
      }
    }
  }
}

//予約ボタン
.contact_btn_wrap {
  ul {
    @include media(m) {
      width: 76%;
      max-width: 534px;
    }
    @include media(ml) {
      display: flex;
      justify-content: space-between;
      margin: 0 auto;
    }
    @include media(xl) {
      width: 80%;
      max-width: 884px;
    }
  }
}

.contact_btn  {
  @include media(xl) {
    width: 48%;
  }
  .reco_btn {
    font-size: 1.6rem;
    padding: 1.3rem 9rem;
    width: 90%;
    @include media(ml) {
      width: 100%;
    }
    @include media(xl) {
      font-size: 19px;
      padding: 5.8% 9rem;
      &::after {
        width: 53px;
        right: 11rem;
      }
    }
  }
}

.contact_btn .reco_btn:hover::after {
  @include media(xl) {
    right: 8rem;
  }
}

//サイト
#PRODUCE {
  padding-top: 4.8rem;
  @include media(m) {
    padding-top: 5.8rem;
  }
  @include media(xl) {
    padding-top: 90px;
  }
}

.site_wrap {
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
  position: relative;
  @include media(xl) {
    width: 90%;
    max-width: 784px;
    margin: 0 auto;
  }
  li {
    flex: 0 0 48%;
  }
}
.site_txt {
  color: #333;
  font-size: 1.4rem;
  text-align: center;
  padding: 1rem 0;
  background-color: #fff;
  line-height: 1.3;
  @include media(xl) {
    font-size: 18px;
    padding: 14px 0;
  }
  span {
    font-size: 1rem;
    font-family: sans-serif;
    @include media(xl) {
      font-size: 12px;
    }
  }
}

.site_wrap-bg {
  position: relative;
  padding-top: 1.8rem;
  @include media(m) {
    padding-top: 3.4rem;
  }
  @include media(xl) {
    padding-top: 58px;
  }
  &::after {
    position: absolute;
    content: '';
    top: 3rem;
    left: 0;
    z-index: -3;
    width: 100%;
    background-color: #f6f6f6;
    height: calc(100% + 1rem);
    @include media(m) {
      top: 5.4rem;
      height: calc(100% + -2rem);
    }
    @include media(xl) {
      top: 10rem;
      height: calc(100% + -6rem);
    }
  }
}

.pd_img {
  transition: all 0.6s;
  &:hover {
    opacity: 0.6;
    transition: all 0.6s;
  }
}

.htw_logo {
  &.f_top_logo {
    @include media(s) {
      padding-top: 3rem;
    }
    @include media(m) {
      padding-top: 1rem;
    }
  }
}

.footer_common {
  padding-top: 0;
}

.footer_common {
  &._top-fotter {
    @include media(xl) {
      padding-top: 44px;
    }
  }
}

//マイページ
.myPage_wrap {
  margin-top: 6.2rem;
  @include media(ml) {
    width: 90%;
    max-width: 1160px;
    margin-left:auto;
    margin-right: auto;
  }
  @include media(xl) {
    margin-top: 7.2rem;
  }
  a {
    width: 42%;
    display: block;
    @include media(s) {
      margin: 0 auto;
    }
    @include media(m) {
      width: 24%;
    }
    @include media(xl) {
      width: 184px;
    }
  }
}

.site_wrap {
  margin: 0 10%;
  display: block;
  @include media(xl) {
    width: 90%;
    max-width: 784px;
    margin: 0 auto;
  }
}
