@charset "UTF-8";

//カラー
$main_color: #c9bc9c;
$font_main_color: #333;
$site_color: #c9bc9c;
$prime_color: #f84e41;
$option_color: #00b7bd;
$color_common: #a89259;

//ブレイクポイント
$breakpoints: (
    xs: "screen and (max-width: 320px)",
    s: "screen and (max-width: 767px)",
    m: "screen and (min-width: 768px) and (max-width: 1023px) ",
    ml: "screen and (min-width: 768px)",
    l: "screen and (max-width: 1023px)",
    xl: "screen and (min-width: 1024px)",
);

@mixin fade {
    transition: all 0.5s ease-in-out 0s;
    opacity: 0.6;
}

//フレキシブルボックスのミックスイン
@mixin flex {
    display: flex;
    justify-content: space-between;
}

@mixin flex_d {
    display: flex;
    flex-direction: column;
}

//フォントのミックスイン
@mixin font_base($value: 13px) {
  font-size: $value;
  line-height: 1.5;
  color: #333;
  letter-spacing: .05em;
}
