@charset "UTF-8";
/*================================================
このCSSファイルはSassから生成されています。
編集しないようにご注意ください。
================================================*/
html {
  font-size: 62.5%;
  /* 10px */
  height: 100%;
}

img {
  width: 100%;
  height: auto;
  vertical-align: bottom;
  display: block;
}

/*スライドドア*/
.img_box {
  width: 100%;
  height: 100%;
  position: relative;
  overflow: hidden;
}
.img_box img {
  transform: translateX(-100%);
}

.img_box.slide--on img {
  width: 100%;
  animation-name: door_2;
  animation-duration: 0.2s;
  animation-timing-function: ease-out;
  animation-delay: 0.8s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: both;
  animation-play-state: running;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
}

.img_box .slide-bg {
  position: absolute;
  top: 0;
  left: 0;
  width: 0;
  height: 100%;
  background-color: #c9bc9c;
  /*-webkit-transition: all 0.3s;
  transition: all 0.3s;*/
}

.img_box.slide--on .slide-bg {
  width: 100%;
  -webkit-animation-fill-mode: both;
  -moz-animation-fill-mode: both;
  animation-fill-mode: both;
  animation-name: door;
  animation-duration: 1.4s;
  animation-timing-function: ease-out;
  animation-delay: 0.25s;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-play-state: running;
}

@keyframes door {
  0% {
    transform: translateX(-100%);
  }
  50% {
    transform: translateX(0);
  }
  100% {
    transform: translateX(100%);
  }
}
@keyframes door_2 {
  0% {
    transform: translateX(-100%);
  }
  100% {
    transform: translateX(0);
  }
}
.main_image {
  margin-top: 57px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .main_image {
    margin-top: 70px;
  }
}
@media screen and (min-width: 1024px) {
  .main_image {
    margin-top: 108px;
  }
}

.image-crossfader {
  overflow: hidden;
  position: relative;
  height: 72vh;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .image-crossfader {
    height: 493px;
  }
}
@media screen and (min-width: 1024px) {
  .image-crossfader {
    height: 76vh;
    min-height: 508px;
    max-height: 825px;
  }
}

.image-crossfader-inner:nth-child(1) {
  background-image: url(../../top/img/slide01_sp.jpg);
}
@media screen and (min-width: 768px) {
  .image-crossfader-inner:nth-child(1) {
    background-image: url(../../top/img/slide01_pc.jpg);
  }
}

.image-crossfader-inner:nth-child(2) {
  background-image: url(../../top/img/slide02_sp.jpg);
}
@media screen and (min-width: 768px) {
  .image-crossfader-inner:nth-child(2) {
    background-image: url(../../top/img/slide02_pc.jpg);
  }
}

.image-crossfader-inner:nth-child(3) {
  background-image: url(../../top/img/slide03_sp.jpg);
}
@media screen and (min-width: 768px) {
  .image-crossfader-inner:nth-child(3) {
    background-image: url(../../top/img/slide03_pc.jpg);
  }
}

.image-crossfader-inner:nth-child(4) {
  background-image: url(../../top/img/slide04_sp.jpg);
}
@media screen and (min-width: 768px) {
  .image-crossfader-inner:nth-child(4) {
    background-image: url(../../top/img/slide04_pc.jpg);
  }
}

.image-crossfader-inner:nth-child(5) {
  background-image: url(../../top/img/slide05_sp.jpg);
}
@media screen and (min-width: 768px) {
  .image-crossfader-inner:nth-child(5) {
    background-image: url(../../top/img/slide05_pc.jpg);
  }
}

.image-crossfader-inner {
  background-size: cover;
  background-position: center;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  z-index: 0;
  opacity: 0;
  transition: opacity 3.5s cubic-bezier(0.455, 0.03, 0.515, 0.955);
}

.image-crossfader-inner.is-visible {
  z-index: 1;
  opacity: 1;
}

/*メインイメージバナー*/
.main_image {
  position: relative;
}

.top_banner_wrap {
  position: absolute;
  top: 1rem;
  left: 0;
  width: 100%;
  z-index: 3;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .top_banner_wrap {
    top: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .top_banner_wrap {
    width: 90%;
    max-width: 1600px;
    left: 50%;
    transform: translateX(-50%);
    top: 30px;
  }
}

.top_banner_img {
  width: 160px;
  margin-left: auto;
  margin-right: 1rem;
}
@media screen and (min-width: 1024px) {
  .top_banner_img {
    margin-right: 0;
    width: 264px;
  }
}

.visual {
  margin-left: 10%;
  margin-right: 10%;
  margin-top: 30px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .visual {
    width: 380px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1024px) {
  .visual {
    width: 568px;
    margin-left: auto;
    margin-right: auto;
  }
}

.movie video {
  width: 100%;
}

.fuul_movie {
  display: flex;
  justify-content: flex-end;
  margin-top: 10px;
}
@media screen and (min-width: 1024px) {
  .fuul_movie {
    margin-top: 16px;
  }
}
.fuul_movie a {
  color: #fff;
  background-color: #c9bc9c;
  padding: 0 10px;
  line-height: 2;
  display: inline-block;
  font-size: 11px;
}
@media screen and (min-width: 1024px) {
  .fuul_movie a {
    font-size: 13px;
  }
}

.guide_line_wrap {
  text-align: center;
  margin-top: 28px;
  margin-bottom: 58px;
}

.guide_line_wrap a {
  color: #333;
  font-family: sans-serif;
  font-size: 3.2vw;
  line-height: 1.5;
  border-bottom: solid 1px #333;
  display: inline-block;
  padding-bottom: 6px;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .guide_line_wrap a {
    font-size: 12px;
  }
}
@media screen and (min-width: 1024px) {
  .guide_line_wrap a {
    font-size: 15px;
    margin-top: 36px;
  }
}

/*会場説明*/
.main_copy_wrap {
  background-color: #f6f6f6;
  padding-bottom: 4.8rem;
}

.top_copy {
  background-color: #fff;
  width: 90%;
  margin: 0 auto;
  padding: 1rem;
  box-sizing: border-box;
  margin-top: -56px;
  z-index: 3;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .top_copy {
    width: 45%;
    max-width: 398px;
  }
}
@media screen and (min-width: 1024px) {
  .top_copy {
    max-width: 587px;
    padding: 11px;
  }
}

.top_copy_inner {
  font-size: 1.5rem;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  letter-spacing: 0.1em;
  line-height: 1.8;
  text-align: center;
  border: double 3px #333;
  padding: 1.3rem 0;
}
@media screen and (min-width: 1024px) {
  .top_copy_inner {
    font-size: 19px;
    padding: 21px;
  }
}
.top_copy_inner h2 {
  font-weight: normal;
}

.store_name {
  font-size: 1.2rem;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  padding-top: 0.6rem;
  font-weight: bold;
}
@media screen and (min-width: 1024px) {
  .store_name {
    font-size: 14px;
    padding-top: 14px;
  }
}

.store-wedding {
  color: #c9bc9c;
  font-family: "Italianno", cursive;
  text-align: center;
  font-size: 3.9rem;
  font-weight: 400;
  letter-spacing: 0.05em;
  padding-top: 3.2rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .store-wedding {
    font-size: 4.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .store-wedding {
    font-size: 58px;
    padding-top: 54px;
  }
}

.stoe_copy {
  font-size: 1.6rem;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-weight: normal;
  text-align: center;
  padding-top: 2.4rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .stoe_copy {
    font-size: 1.8rem;
    padding-top: 2.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .stoe_copy {
    font-size: 21px;
    padding-top: 18px;
  }
}

.copy_lead {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  margin: 2rem 5% 0;
  line-height: 1.6;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .copy_lead {
    line-height: 1.5;
    font-size: 14px;
  }
}
@media screen and (min-width: 768px) {
  .copy_lead {
    text-align: center;
  }
}
@media screen and (min-width: 1024px) {
  .copy_lead {
    font-size: 17px;
    line-height: 1.6;
    margin-top: 31px;
  }
}

/*選ばれる理由*/
.ttl_reason {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  text-align: center;
  line-height: 1.8;
  font-size: 1.8rem;
  font-size: 4.375vw;
  font-weight: normal;
  margin-top: 5.2rem;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ttl_reason {
    font-size: 17px;
  }
}
@media screen and (min-width: 1024px) {
  .ttl_reason {
    font-size: 22px;
    margin-top: 101px;
  }
}
.ttl_reason::before {
  display: inline-block;
  position: absolute;
  content: "";
  background: url(../../top/img/reason_bg.png) center no-repeat;
  width: 234px;
  height: 45px;
  top: -2.6rem;
  left: 50%;
  transform: translateX(-50%);
  background-size: contain;
  z-index: -1;
}
@media screen and (min-width: 1024px) {
  .ttl_reason::before {
    width: 346px;
    height: 66px;
    top: -38px;
  }
}
.ttl_reason span {
  font-family: "Dancing Script", cursive;
  font-size: 2.2rem;
}

@media screen and (min-width: 768px) {
  .reason_list {
    width: 32.155172%;
  }
}

.reason_box {
  margin: 0 5%;
  padding-top: 2.6rem;
}
@media screen and (max-width: 767px) {
  .reason_box li:not(:first-child) {
    padding-top: 3.6rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reason_box {
    width: 90%;
  }
}
@media screen and (min-width: 768px) {
  .reason_box {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  .reason_box {
    width: 90%;
    max-width: 1030px;
  }
}

.reason_image {
  width: 47.262536%;
}
@media screen and (min-width: 768px) {
  .reason_image {
    width: 73.28418%;
    margin: 0 auto;
  }
}

.reason_lead {
  width: 77%;
  box-shadow: 5px 5px #f6f6f6;
  margin-left: auto;
  margin-top: -5rem;
  z-index: 3;
  position: relative;
  background-color: rgba(255, 255, 255, 0.96);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reason_lead {
    margin: -3.8rem auto 0;
    width: 96%;
  }
}
@media screen and (min-width: 768px) {
  .reason_lead {
    display: flex;
    justify-content: center;
  }
}
@media screen and (min-width: 1024px) {
  .reason_lead {
    width: 100%;
  }
}
.reason_lead h4 {
  font-weight: normal;
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-size: 3.73vw;
  position: relative;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reason_lead h4 {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .reason_lead h4 {
    font-size: 17px;
  }
}

.reason_left {
  margin-left: auto;
}

.reason_right {
  margin-right: auto;
  margin-left: 0;
}

.reason_01 {
  position: relative;
  padding: 1.6em 1rem 1rem 2rem;
}
@media screen and (min-width: 1024px) {
  .reason_01 {
    padding: 2em 0 1.3rem 2rem;
  }
}
.reason_01::before {
  position: absolute;
  content: "01";
  font-family: "Dancing Script", cursive;
  top: 0;
  left: 3%;
  font-size: 2.8rem;
  color: #d0d0d0;
  z-index: -1;
}
@media screen and (min-width: 1024px) {
  .reason_01::before {
    font-size: 4.2rem;
    top: -2px;
  }
}

.reason_02 {
  position: relative;
  padding: 1.6em 1rem 1rem 2rem;
}
@media screen and (min-width: 1024px) {
  .reason_02 {
    padding: 2em 0 1.3rem 2rem;
  }
}
.reason_02::before {
  position: absolute;
  content: "02";
  font-family: "Dancing Script", cursive;
  top: 0;
  left: 3%;
  font-size: 2.8rem;
  color: #d0d0d0;
  z-index: -1;
}
@media screen and (min-width: 1024px) {
  .reason_02::before {
    font-size: 4.2rem;
    top: -2px;
  }
}

.reason_03 {
  position: relative;
  padding: 1.6em 1rem 1rem 2rem;
}
@media screen and (min-width: 1024px) {
  .reason_03 {
    padding: 2em 0 1.3rem 2rem;
  }
}
.reason_03::before {
  position: absolute;
  content: "03";
  font-family: "Dancing Script", cursive;
  top: 0;
  left: 3%;
  font-size: 2.8rem;
  color: #d0d0d0;
  z-index: -1;
}
@media screen and (min-width: 1024px) {
  .reason_03::before {
    font-size: 4.2rem;
    top: -2px;
  }
}

.reason_txt {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-size: 1.3rem;
  padding-top: 2rem;
}
@media screen and (max-width: 767px) {
  .reason_txt {
    margin: 0 5%;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reason_txt {
    font-size: 1rem;
  }
}
@media screen and (min-width: 1024px) {
  .reason_txt {
    font-size: 14px;
    margin: 0 28px;
  }
}

/*トピック*/
#topics {
  padding-top: 4.6rem;
}

.ttl_outer {
  text-align: center;
  z-index: 3;
  position: relative;
}

.ttl_topic {
  color: #333;
  font-size: 2.5rem;
}

.ttl_topic {
  display: inline-block;
  font-family: "Cormorant Garamond", serif;
  font-weight: 500;
  font-size: 2.4rem;
  letter-spacing: 0.05em;
  text-align: center;
  position: relative;
  color: #333;
  line-height: 2;
}
.ttl_topic::before {
  position: absolute;
  content: "";
  border-top: solid 1px #333;
  width: 80%;
  height: 1px;
  left: 50%;
  transform: translateX(-50%);
}
.ttl_topic::after {
  position: absolute;
  content: "";
  border-top: solid 1px #333;
  width: 80%;
  height: 1px;
  left: 50%;
  bottom: 0;
  transform: translateX(-50%);
}

.topics_box {
  background-color: #f6f6f6;
  padding: 4rem 4%;
  margin-top: -2.5rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .topics_box {
    width: 58%;
    padding: 5rem 5% 4rem;
  }
}
@media screen and (min-width: 768px) {
  .topics_box {
    margin: -2.5rem auto 0;
  }
}
@media screen and (min-width: 1024px) {
  .topics_box {
    width: 698px;
    box-sizing: border-box;
  }
}

.topics_inner {
  background-color: #fff;
  padding: 2rem 1rem;
}
@media screen and (min-width: 1024px) {
  .topics_inner {
    padding: 29px 40px;
  }
}

.type_box {
  display: block;
  color: #333;
}
.type_box:not(:last-child) {
  border-bottom: solid 1px #333;
  padding-bottom: 1.2rem;
}
.type_box:not(:first-of-type) {
  padding-top: 1.2rem;
}

.topics_type {
  background-color: #000;
  color: #fff;
  line-height: 1.5;
  letter-spacing: 0.05em;
  padding: 0 0.8rem;
  font-weight: normal;
  display: inline-block;
  margin-bottom: 1rem;
}
@media screen and (min-width: 1024px) {
  .topics_type {
    font-size: 12px;
    margin-bottom: 12px;
  }
}

.type_inner {
  display: flex;
  align-items: center;
}

.topic_img_outer {
  width: 24.856596%;
}

.topic_img {
  position: relative;
  width: 100%;
  height: 0;
  padding-top: 90%;
}
.topic_img img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.topic_info {
  flex: 1;
  padding-left: 1.2rem;
}
@media screen and (min-width: 1024px) {
  .topic_info {
    padding-left: 39px;
  }
}

.topics_day {
  font-size: 1.3rem;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  line-height: 1;
  padding-bottom: 0.6rem;
}
@media screen and (min-width: 1024px) {
  .topics_day {
    font-size: 15px;
    padding-bottom: 17px;
  }
}

.topics_txt {
  line-height: 1.5;
}
@media screen and (min-width: 1024px) {
  .topics_txt {
    font-size: 14px;
  }
}

/*オススメフェア＆プラン*/
#recommended {
  margin-top: 7.2rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #recommended {
    margin-top: 9.2rem;
  }
}

.ttl_sp {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  text-align: center;
  font-size: 1.8rem;
  font-weight: normal;
  position: relative;
  letter-spacing: 0.1em;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ttl_sp {
    font-size: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .ttl_sp {
    font-size: 23px;
  }
}
.ttl_sp span {
  font-family: sans-serif;
  font-size: 1.2rem;
  line-height: 1.5;
  display: inline-block;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ttl_sp span {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .ttl_sp span {
    font-size: 14px;
  }
}

@media screen and (min-width: 1024px) {
  .ttl_recommended {
    margin-top: 14.2rem;
  }
}
.ttl_recommended::before {
  display: inline-block;
  position: absolute;
  content: "";
  background: url(../../top/img/recommended.png) center no-repeat;
  width: 318px;
  height: 45px;
  top: -3.2rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: contain;
  z-index: -1;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ttl_recommended::before {
    width: 418px;
    height: 49px;
    top: -4rem;
  }
}
@media screen and (min-width: 1024px) {
  .ttl_recommended::before {
    width: 560px;
    height: 66px;
    top: -5.2rem;
  }
}

@media screen and (min-width: 1024px) {
  .ttl_privilege {
    margin-top: 193px;
  }
}
.ttl_privilege::before {
  display: inline-block;
  position: absolute;
  content: "";
  background: url(../../top/img/privilege.png) center no-repeat;
  width: 222px;
  height: 82px;
  top: -3.4rem;
  left: 50%;
  -webkit-transform: translateX(-50%);
  transform: translateX(-50%);
  background-size: contain;
  z-index: -1;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ttl_privilege::before {
    width: 232px;
    height: 86px;
  }
}
@media screen and (min-width: 1024px) {
  .ttl_privilege::before {
    width: 327px;
    height: 120px;
    top: -5.2rem;
  }
}

/*オススメフェア*/
.ttl_category {
  font-family: "Cormorant Garamond", serif;
  font-weight: normal;
  font-size: 2.8rem;
  display: flex;
  align-items: center;
  border-bottom: solid 1px #333;
  padding-bottom: 0.5rem;
}
.ttl_category span {
  font-size: 1.1rem;
  display: inline-block;
  padding-left: 1.6rem;
  font-family: sans-serif;
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .recommended_box {
    margin-top: 3.6rem;
  }
}
@media screen and (min-width: 768px) {
  .recommended_box {
    display: flex;
    align-items: flex-end;
  }
  .recommended_box._reco-fair {
    flex-direction: row-reverse;
  }
}
@media screen and (min-width: 1024px) {
  .recommended_box {
    margin-top: 53px;
  }
}

.recommended_img_wrap {
  width: 100%;
}
@media screen and (min-width: 768px) {
  .recommended_img_wrap {
    width: 59.902912%;
    max-width: 617px;
  }
}

.recommended_img {
  position: relative;
  width: 100%;
  padding-top: 62.72285%;
}
@media screen and (max-width: 767px) {
  .recommended_img {
    margin: 2.8rem auto 0;
  }
}

.inner_img {
  object-fit: cover;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.ttl_fair {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px #333;
  padding-bottom: 0.8rem;
}
.ttl_fair h5 {
  font-weight: normal;
  font-size: 1.8rem;
}
@media screen and (min-width: 1024px) {
  .ttl_fair h5 {
    font-size: 23px;
  }
}

.recommended_wrap {
  padding-top: 3.8rem;
  margin: 0 5%;
}
@media screen and (min-width: 1024px) {
  .recommended_wrap {
    width: 1030px;
    margin: 0 auto;
  }
}
.recommended_wrap .ttl_category:not(:first-of-type) {
  padding-top: 8.2rem;
}
@media screen and (min-width: 1024px) {
  .recommended_wrap .ttl_category:not(:first-of-type) {
    padding-top: 148px;
  }
}

.schedule {
  font-family: sans-serif;
  font-weight: bold;
  font-size: 1.4rem;
  color: #f84e41;
}
@media screen and (min-width: 1024px) {
  .schedule {
    font-size: 20px;
    font-weight: normal;
  }
}

.recommended_txt_wrap {
  background-color: rgba(255, 255, 255, 0.86);
  width: 85%;
  z-index: 3;
  position: relative;
  padding: 1rem 1rem 2.4rem 1rem;
}
@media screen and (min-width: 768px) {
  .recommended_txt_wrap {
    box-shadow: 8px 8px #f6f6f6;
  }
}
@media screen and (max-width: 767px) {
  .recommended_txt_wrap {
    margin: -2.2rem auto 0;
    border-bottom: solid 4px #f6f6f6;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .recommended_txt_wrap {
    width: 51.21359%;
    padding: 1rem 1.8rem 2.4rem 1.8rem;
  }
  .recommended_txt_wrap._reco-fair-txt {
    margin-right: -10rem;
    margin-bottom: -4rem;
    box-shadow: -8px 8px #f6f6f6;
  }
  .recommended_txt_wrap._reco-plan-txt {
    margin-left: -10rem;
    margin-bottom: -4rem;
  }
}
@media screen and (min-width: 1024px) {
  .recommended_txt_wrap {
    width: 56.21359%;
  }
  .recommended_txt_wrap._reco-fair-txt {
    margin-right: -16.019417%;
    margin-bottom: -79px;
    padding: 31px 38px 34px;
    box-shadow: -8px 8px #f6f6f6;
  }
  .recommended_txt_wrap._reco-plan-txt {
    margin-left: -16.019417%;
    margin-bottom: -79px;
    padding: 31px 38px 34px;
  }
}

.recommended_txt dt {
  color: #f84e41;
  font-size: 1.6rem;
  line-height: 1.4;
  font-weight: bold;
  padding-top: 1.6rem;
}
@media screen and (min-width: 768px) {
  .recommended_txt dt {
    line-height: 1.5;
  }
}
.recommended_txt dt .number, .recommended_txt dt .tax {
  font-size: 1.2rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .recommended_txt dt .number, .recommended_txt dt .tax {
    font-size: 1.1rem;
  }
}
@media screen and (min-width: 1024px) {
  .recommended_txt dt {
    font-size: 19px;
    font-weight: normal;
    padding-top: 18px;
  }
  .recommended_txt dt .number, .recommended_txt dt .tax {
    font-size: 14px;
  }
}
.recommended_txt dd {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-size: 1.3rem;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 4;
  overflow: hidden;
  width: 100%;
  padding-top: 2rem;
}
@media screen and (min-width: 768px) {
  .recommended_txt dd {
    -webkit-line-clamp: 3;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .recommended_txt dd {
    font-size: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .recommended_txt dd {
    font-size: 14px;
    line-height: 1.8;
  }
}
.recommended_txt ._reco-plan {
  color: #00b7bd;
}
.recommended_txt ._reco-plan span {
  display: inline-block;
}

.reco_btn {
  position: relative;
  display: block;
  color: #333;
  font-size: 1.4rem;
  border: solid 1px #333;
  width: 23rem;
  margin: 0 auto;
  padding: 0.8rem 5rem;
  box-sizing: border-box;
  margin-top: 3.2rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reco_btn {
    width: 18rem;
  }
}
@media screen and (min-width: 1024px) {
  .reco_btn {
    font-size: 18px;
  }
  .reco_btn:hover::after {
    right: 4.2rem;
  }
}
.reco_btn::after {
  position: absolute;
  content: "";
  background: url(../../common/img/btn_allow.svg) no-repeat center;
  background-size: contain;
  top: 50%;
  transform: translateY(-50%);
  right: 6rem;
  width: 35px;
  height: 100%;
  transition: right 0.2s ease-in;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .reco_btn::after {
    right: 3rem;
  }
}

/*選べるスタイル*/
#CEREMONY {
  position: relative;
  margin-top: 6rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #CEREMONY {
    margin-top: 11rem;
  }
}
@media screen and (min-width: 1024px) {
  #CEREMONY {
    margin-top: 180px;
  }
}
#CEREMONY::after {
  content: "";
  position: absolute;
  top: 14.4rem;
  left: 0;
  z-index: -3;
  width: 100%;
  background-color: #f6f6f6;
  height: calc(100% - 9rem);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #CEREMONY::after {
    height: calc(100% - 11rem);
  }
}
@media screen and (min-width: 1024px) {
  #CEREMONY::after {
    top: 192px;
    height: calc(100% - 13rem);
  }
}

.col_3_box {
  padding-top: 3.6rem;
}
@media screen and (min-width: 768px) {
  .col_3_box {
    display: flex;
    justify-content: space-between;
  }
}
.col_3_box li {
  width: 74%;
  background-color: #fff;
}
@media screen and (min-width: 768px) {
  .col_3_box li {
    width: 31.5517241%;
  }
}
@media screen and (max-width: 767px) {
  .col_3_box li:not(:first-child) {
    margin-top: 3.2rem;
  }
  .col_3_box li:nth-child(2) {
    margin-left: auto;
  }
}
.col_3_box li img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1.2s;
  transition: all 1.2s;
}
.col_3_box a {
  display: block;
}

@media screen and (min-width: 1024px) {
  .col_3_box li:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 1.1s;
    transition: all 1.1s;
  }
}

.expansion {
  overflow: hidden;
}

.ceremony_txt {
  display: inline-block;
  color: #333;
  font-size: 1.3rem;
  background-color: #fff;
  padding: 1.5rem 3rem 1.5rem 1rem;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .ceremony_txt {
    font-size: 17px;
    padding: 20px 28px 20px 20px;
  }
}
.ceremony_txt::after {
  display: block;
  content: ">";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 0;
}

/*パーティー*/
#party {
  padding-top: 10rem;
}
@media screen and (min-width: 1024px) {
  #party {
    padding-top: 170px;
  }
}

.slide_box._party {
  margin-top: 3.2rem;
  box-shadow: 8px 8px #a89259;
}
@media screen and (min-width: 768px) {
  .slide_box._party {
    display: flex;
    justify-content: space-between;
  }
}
@media screen and (min-width: 1024px) {
  .slide_box._party {
    margin-top: 52px;
  }
}

.slide_box_txt {
  padding: 2rem 1.8rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .slide_box_txt {
    padding: 0 2rem;
  }
}
@media screen and (min-width: 768px) {
  .slide_box_txt {
    width: 40.344827%;
    box-sizing: border-box;
    display: flex;
    flex-direction: column;
    justify-content: center;
  }
}
@media screen and (min-width: 768px) and (min-width: 1024px) {
  .slide_box_txt {
    padding: 0 5% 0 5%;
  }
}
.slide_box_txt dt {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-size: 1.8rem;
  line-height: 1;
}
.slide_box_txt dt span {
  font-size: 1.2rem;
}
.slide_box_txt dd {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  padding-top: 1.6rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .slide_box_txt dd {
    padding-top: 2.6rem;
  }
}
@media screen and (min-width: 1024px) {
  .slide_box_txt dd {
    font-size: 16px;
    padding-top: 13%;
  }
}

@media screen and (min-width: 768px) {
  .img_wrap {
    width: 59.655172%;
    box-sizing: border-box;
  }
}

@media screen and (max-width: 767px) {
  .reco_btn._slide-btn {
    width: 19rem;
  }
}
@media screen and (max-width: 767px) {
  .reco_btn._slide-btn::after {
    right: 3rem;
  }
}
@media screen and (min-width: 1024px) {
  .reco_btn._slide-btn {
    margin-top: 16%;
    padding: 1.2rem 5rem;
  }
}

/*フォント見出し-TB*/
@media (min-width: 768px) {
  .slide_box_txt dt {
    font-size: calc(4 * (100vw - 768px) / 255 + 16px);
  }
}
@media (min-width: 1023px) {
  .slide_box_txt dt {
    font-size: 20px;
  }
}
/*フォントspan-TB*/
@media (min-width: 768px) {
  .slide_box_txt dt span {
    font-size: calc(2 * (100vw - 768px) / 255 + 12px);
  }
}
@media (min-width: 1023px) {
  .slide_box_txt dt span {
    font-size: 12px;
  }
}
/*フォントテキスト-TB*/
@media (min-width: 768px) {
  .slide_box_txt dd {
    font-size: calc(3 * (100vw - 768px) / 255 + 14px);
  }
}
@media (min-width: 1023px) {
  .slide_box_txt dd {
    font-size: 14px;
  }
}
/*フォント見出し-PC*/
@media (min-width: 1023px) {
  .slide_box_txt dt {
    font-size: calc(3 * (100vw - 1024px) / 576 + 24px);
  }
}
@media (min-width: 1600px) {
  .slide_box_txt dt {
    font-size: 28px;
  }
}
/*フォントspan-PC*/
@media (min-width: 1023px) {
  .slide_box_txt dt span {
    font-size: calc(2 * (100vw - 1024px) / 576 + 14px);
  }
}
@media (min-width: 1600px) {
  .slide_box_txt dt span {
    font-size: 14px;
  }
}
/*フォントテキスト-PC*/
@media (min-width: 1023px) {
  .slide_box_txt dd {
    font-size: calc(3 * (100vw - 1024px) / 576 + 15px);
  }
}
@media (min-width: 1600px) {
  .slide_box_txt dd {
    font-size: 17 px;
  }
}
/* Safari 7.1+ に適用される */
_::-webkit-full-page-media, _:future, :root, body {
  -webkit-animation: safariFix 1s forwards infinite;
}

@-webkit-keyframes safariFix {
  100% {
    z-index: 1;
  }
}
/*バーチャルツアー*/
#EXPERIENCE {
  position: relative;
  margin-top: 6rem;
}
@media screen and (min-width: 1024px) {
  #EXPERIENCE {
    padding-top: 93px;
  }
}
#EXPERIENCE::after {
  content: "";
  position: absolute;
  top: 16.4rem;
  left: 0;
  z-index: -3;
  width: 100%;
  background-color: #f6f6f6;
  height: calc(100% - 11rem);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #EXPERIENCE::after {
    height: calc(100% - 11rem);
  }
}
@media screen and (min-width: 1024px) {
  #EXPERIENCE::after {
    top: 322px;
    height: calc(100% - 26rem);
  }
}

@media screen and (max-width: 767px) {
  .ttl_cont_sub.ttl_vr {
    font-size: 3.4375vw;
  }
}

/*料理*/
#CUISINE {
  padding-top: 11rem;
}
@media screen and (min-width: 1024px) {
  #CUISINE {
    padding-top: 156px;
  }
}

@media screen and (min-width: 1024px) {
  .slide_box._party._cuisine {
    flex-direction: row-reverse;
    box-shadow: -8px 8px #a89259;
  }
}

/*ドレス*/
#DRESS {
  padding-top: 6rem;
  position: relative;
}
#DRESS::after {
  position: absolute;
  content: "";
  top: 19.4rem;
  left: 0;
  z-index: -3;
  width: 100%;
  background-color: #f6f6f6;
  height: calc(100% - 15rem);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #DRESS::after {
    height: calc(100% - 16rem);
  }
}
@media screen and (min-width: 1024px) {
  #DRESS::after {
    top: 294px;
    height: calc(100% - 24rem);
  }
}
@media screen and (min-width: 1024px) {
  #DRESS {
    padding-top: 97px;
  }
}

@media screen and (min-width: 1024px) {
  .cont_wrap.dress_warap {
    max-width: 982px;
  }
}

.costume_box {
  position: relative;
  padding-top: 3.2rem;
}
@media screen and (min-width: 768px) {
  .costume_box {
    display: flex;
    justify-content: space-between;
  }
}
.costume_box li {
  box-sizing: border-box;
}
@media screen and (min-width: 768px) {
  .costume_box li {
    flex: 0 48.26883%;
  }
}
@media screen and (max-width: 767px) {
  .costume_box li:not(:first-child) {
    margin-top: 4.8rem;
  }
}
.costume_box .media-link {
  display: block;
  position: relative;
  overflow: hidden;
}
.costume_box .media-link img {
  -webkit-transform: scale(1);
  -ms-transform: scale(1);
  transform: scale(1);
  -webkit-transition: all 1.2s;
  transition: all 1.2s;
}
.costume_box .media-link::before {
  position: absolute;
  content: "";
  border: double 3px #fff;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 83%;
  height: 86%;
  z-index: 3;
  box-sizing: border-box;
}
.costume_box .media-link::after {
  content: "";
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.3);
  box-sizing: border-box;
  color: #FFFFFF;
  overflow: hidden;
  -webkit-transition: all 1.2s;
  transition: all 1.2s;
}

@media screen and (min-width: 1024px) {
  .media-link:hover img {
    -webkit-transform: scale(1.1);
    -ms-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-transition: all 1.1s;
    transition: all 1.1s;
  }
  .costume_box .media-link:hover::after {
    opacity: 0.4;
    -webkit-transition: all 1.2s;
    transition: all 1.2s;
  }
}
.title {
  position: absolute;
  top: 0;
  left: 0;
  color: #fff;
  z-index: 99;
  top: 50%;
  left: 50%;
  -webkit-transform: translate(-50%, -50%);
  -moz-transform: translate(-50%, -50%);
  -ms-transform: translate(-50%, -50%);
  -o-transform: translate(-50%, -50%);
  transform: translate(-50%, -50%);
  box-sizing: border-box;
  width: 54%;
  text-align: center;
  letter-spacing: 0.2em;
  padding: 2.6% 0;
  font-weight: 100;
  font-size: 1.5rem;
}

.ttl_under {
  font-size: 2.4rem;
  letter-spacing: 0.25em;
  line-height: 0.8;
}
.ttl_under span {
  font-size: 1.3rem;
  letter-spacing: 0.1em;
  font-family: sans-serif;
}

#BANNER_BOX {
  margin-top: 12.2rem;
}

.sp_banner_wrap {
  padding-top: 2rem;
}
@media screen and (min-width: 768px) {
  .sp_banner_wrap {
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
@media screen and (min-width: 1024px) {
  .sp_banner_wrap {
    padding-top: 48px;
  }
}
@media screen and (max-width: 767px) {
  .sp_banner_wrap li:not(:first-child) {
    padding-top: 1.8rem;
  }
}
@media screen and (min-width: 768px) {
  .sp_banner_wrap li {
    width: 49%;
  }
}
.sp_banner_wrap li:not(:first-of-type) {
  padding-top: 1rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .sp_banner_wrap li:not(:first-of-type) {
    padding-top: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .sp_banner_wrap li:not(:first-of-type) {
    padding-top: 24px;
  }
}

#banner_wrap {
  margin: 0 10%;
}

#slider {
  margin: 0 auto 30px;
}
@media screen and (max-width: 767px) {
  #slider {
    margin: 0 8%;
  }
}
@media screen and (min-width: 768px) {
  #slider {
    max-width: 680px;
    width: 64%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  #slider {
    margin: 0 auto 40px;
  }
}

.slide-item img {
  width: 100%;
}

button {
  margin: 0;
  padding: 0;
  background: none;
  border: none;
  border-radius: 0;
  outline: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.slide-arrow {
  position: absolute;
  top: 50%;
}

.prev-arrow {
  left: -6%;
  width: 26px;
  height: 26px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: translateY(-50%) rotate(225deg);
  z-index: 1;
}
@media screen and (max-width: 767px) {
  .prev-arrow {
    width: 15px;
    height: 15px;
  }
}

.next-arrow {
  right: -6%;
  width: 26px;
  height: 26px;
  border-top: 1px solid #333;
  border-right: 1px solid #333;
  transform: translateY(-50%) rotate(45deg);
}
@media screen and (max-width: 767px) {
  .next-arrow {
    width: 15px;
    height: 15px;
  }
}

.slide_wrap {
  padding-top: 1.4rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .slide_wrap {
    padding-top: 4.4rem;
    width: 100%;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  .slide_wrap {
    padding-top: 44px;
  }
}

.banner_txt {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  padding-top: 0.4rem;
}

.banner_txt-header {
  color: #a89259;
  font-family: sans-serif;
  font-size: 1.4rem;
  letter-spacing: 0.05em;
  line-height: 1.5;
  padding-top: 0.6rem;
}
@media screen and (min-width: 1024px) {
  .banner_txt-header {
    font-size: 16px;
  }
}
.banner_txt-header a {
  color: #a89259;
}

.banner_lead {
  font-size: 1.2rem;
  line-height: 1.5;
  letter-spacing: 0.05em;
  padding-top: 0.4rem;
}
@media screen and (min-width: 1024px) {
  .banner_lead {
    font-size: 15px;
  }
}

#ACCESS {
  padding-top: 4.2rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #ACCESS {
    padding-top: 3.2rem;
  }
}
@media screen and (min-width: 1024px) {
  #ACCESS {
    padding-top: 84px;
  }
}

.ttl_cont_sub._ttl-access {
  font-size: 3.4375vw;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .ttl_cont_sub._ttl-access {
    font-size: 13px;
  }
}
@media screen and (min-width: 1024px) {
  .ttl_cont_sub._ttl-access {
    font-size: 19px;
  }
}

.map_img {
  width: 53%;
  margin-top: -2rem;
  z-index: 3;
  position: relative;
}
@media screen and (min-width: 768px) {
  .map_img {
    flex: 0 0 40.60344%;
    margin-right: -9.137931%;
    margin-top: 19%;
  }
}

.location {
  width: 92%;
  margin-left: auto;
}
@media screen and (min-width: 768px) {
  .location {
    position: relative;
    width: 100%;
    padding-top: 38.9685%;
    height: 0;
  }
}
.location iframe {
  width: 100%;
  height: 22rem;
}
@media screen and (min-width: 768px) {
  .location iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.location_wrap {
  display: flex;
}
@media screen and (max-width: 767px) {
  .location_wrap {
    padding-top: 3.2rem;
    flex-direction: column-reverse;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .location_wrap {
    padding-top: 3.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .location_wrap {
    padding-top: 52px;
  }
}

@media screen and (max-width: 767px) {
  .access_info {
    padding-top: 2.1rem;
  }
}
@media screen and (min-width: 768px) {
  .access_info {
    width: 54.6551724%;
    margin-left: auto;
    margin-top: -7%;
  }
}

.address dt {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-size: 1.6rem;
  padding-bottom: 1.3rem;
}
@media screen and (min-width: 1024px) {
  .address dt {
    font-size: 18px;
  }
}
.address dd {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  font-size: 1.3rem;
  line-height: 1.6;
}
@media screen and (min-width: 1024px) {
  .address dd {
    font-size: 14px;
    line-height: 1.8;
  }
}

@media screen and (min-width: 768px) {
  .route_tel-wrap {
    display: flex;
    align-items: center;
    flex-direction: row-reverse;
    justify-content: flex-end;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .route_tel-wrap {
    padding-top: 1.2rem;
  }
}
@media screen and (min-width: 1024px) {
  .route_tel-wrap {
    padding-top: 21px;
  }
}

@media screen and (max-width: 767px) {
  .access_tel-outer {
    text-align: center;
    padding-top: 3.6rem;
  }
}

.access_tel {
  font-size: 2rem;
  box-sizing: border-box;
  color: #333;
  display: inline-block;
  letter-spacing: 0.1em;
  padding: 1rem 3rem 1rem 6rem;
  position: relative;
}
@media screen and (max-width: 767px) {
  .access_tel {
    border: solid 1px #333;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .access_tel {
    padding: 1rem 3rem 1rem 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .access_tel {
    font-size: 23px;
    padding: 1rem 3rem 1rem 3rem;
  }
}
.access_tel::before {
  content: "";
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 1.8rem;
  background: url(../../common/img/icon_tel.svg) center no-repeat;
  background-size: contain;
  width: 13px;
  height: 100%;
}
@media screen and (min-width: 768px) {
  .access_tel::before {
    left: 0;
  }
}
@media screen and (min-width: 1024px) {
  .access_tel::before {
    width: 17px;
  }
}

.reception_time-outer {
  display: flex;
  justify-content: center;
}
@media screen and (min-width: 768px) {
  .reception_time-outer {
    justify-content: flex-start;
  }
}

.reception_time {
  font-size: 13px;
  line-height: 1.5;
  color: #333;
  letter-spacing: 0.05em;
  padding-top: 1.2rem;
  font-size: 1.3rem;
  text-align: right;
}
@media screen and (min-width: 1024px) {
  .reception_time {
    font-size: 16px;
  }
}

.route_btn a {
  display: block;
  color: #333;
  font-size: 1.3rem;
  position: relative;
  padding-left: 2rem;
}
@media screen and (max-width: 767px) {
  .route_btn a {
    margin-top: 2rem;
  }
}
@media screen and (min-width: 1024px) {
  .route_btn a {
    font-size: 14px;
    padding-left: 3.2rem;
  }
}
.route_btn a::before {
  position: absolute;
  content: "";
  top: 50%;
  transform: translateY(-50%);
  left: 0;
  background: url(../../common/img/icon_filled_point.svg) center no-repeat;
  background-size: contain;
  width: 15px;
  height: 20px;
}
@media screen and (min-width: 1024px) {
  .route_btn a::before {
    width: 18px;
    height: 29px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1023px) {
  .contact_btn_wrap ul {
    width: 76%;
    max-width: 534px;
  }
}
@media screen and (min-width: 768px) {
  .contact_btn_wrap ul {
    display: flex;
    justify-content: space-between;
    margin: 0 auto;
  }
}
@media screen and (min-width: 1024px) {
  .contact_btn_wrap ul {
    width: 80%;
    max-width: 884px;
  }
}

@media screen and (min-width: 1024px) {
  .contact_btn {
    width: 48%;
  }
}
.contact_btn .reco_btn {
  font-size: 1.6rem;
  padding: 1.3rem 9rem;
  width: 90%;
}
@media screen and (min-width: 768px) {
  .contact_btn .reco_btn {
    width: 100%;
  }
}
@media screen and (min-width: 1024px) {
  .contact_btn .reco_btn {
    font-size: 19px;
    padding: 5.8% 9rem;
  }
  .contact_btn .reco_btn::after {
    width: 53px;
    right: 11rem;
  }
}

@media screen and (min-width: 1024px) {
  .contact_btn .reco_btn:hover::after {
    right: 8rem;
  }
}

#PRODUCE {
  padding-top: 4.8rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  #PRODUCE {
    padding-top: 5.8rem;
  }
}
@media screen and (min-width: 1024px) {
  #PRODUCE {
    padding-top: 90px;
  }
}

.site_wrap {
  margin: 0 5%;
  display: flex;
  justify-content: space-between;
  position: relative;
}
@media screen and (min-width: 1024px) {
  .site_wrap {
    width: 90%;
    max-width: 784px;
    margin: 0 auto;
  }
}
.site_wrap li {
  flex: 0 0 48%;
}

.site_txt {
  color: #333;
  font-size: 1.4rem;
  text-align: center;
  padding: 1rem 0;
  background-color: #fff;
  line-height: 1.3;
}
@media screen and (min-width: 1024px) {
  .site_txt {
    font-size: 18px;
    padding: 14px 0;
  }
}
.site_txt span {
  font-size: 1rem;
  font-family: sans-serif;
}
@media screen and (min-width: 1024px) {
  .site_txt span {
    font-size: 12px;
  }
}

.site_wrap-bg {
  position: relative;
  padding-top: 1.8rem;
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .site_wrap-bg {
    padding-top: 3.4rem;
  }
}
@media screen and (min-width: 1024px) {
  .site_wrap-bg {
    padding-top: 58px;
  }
}
.site_wrap-bg::after {
  position: absolute;
  content: "";
  top: 3rem;
  left: 0;
  z-index: -3;
  width: 100%;
  background-color: #f6f6f6;
  height: calc(100% + 1rem);
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .site_wrap-bg::after {
    top: 5.4rem;
    height: calc(100% - 2rem);
  }
}
@media screen and (min-width: 1024px) {
  .site_wrap-bg::after {
    top: 10rem;
    height: calc(100% - 6rem);
  }
}

.pd_img {
  transition: all 0.6s;
}
.pd_img:hover {
  opacity: 0.6;
  transition: all 0.6s;
}

@media screen and (max-width: 767px) {
  .htw_logo.f_top_logo {
    padding-top: 3rem;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .htw_logo.f_top_logo {
    padding-top: 1rem;
  }
}

.footer_common {
  padding-top: 0;
}

@media screen and (min-width: 1024px) {
  .footer_common._top-fotter {
    padding-top: 44px;
  }
}

.myPage_wrap {
  margin-top: 6.2rem;
}
@media screen and (min-width: 768px) {
  .myPage_wrap {
    width: 90%;
    max-width: 1160px;
    margin-left: auto;
    margin-right: auto;
  }
}
@media screen and (min-width: 1024px) {
  .myPage_wrap {
    margin-top: 7.2rem;
  }
}
.myPage_wrap a {
  width: 42%;
  display: block;
}
@media screen and (max-width: 767px) {
  .myPage_wrap a {
    margin: 0 auto;
  }
}
@media screen and (min-width: 768px) and (max-width: 1023px) {
  .myPage_wrap a {
    width: 24%;
  }
}
@media screen and (min-width: 1024px) {
  .myPage_wrap a {
    width: 184px;
  }
}

.site_wrap {
  margin: 0 10%;
  display: block;
}
@media screen and (min-width: 1024px) {
  .site_wrap {
    width: 90%;
    max-width: 784px;
    margin: 0 auto;
  }
}